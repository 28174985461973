import ProgressiveImg2 from "components/utils/Image/ProgressiveImg2";
import { APP_API_URI, BLOG_API_URI } from "config/config";
import DOMPurify from "isomorphic-dompurify";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import BlogPostTags from "./BlogPostTags";
import { BlogPostMetadata } from "./index";

import placeholderSrc from "assets/images/placeholders/placeholder_900x600.webp";

import { daysAgo } from "components/utils/Utils";
import "yet-another-react-lightbox/styles.css";

const proxy = `${BLOG_API_URI}`;

// Add a hook to make all links point to a proxy
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  // proxy form actions
  // if ("action" in node) {
  //   node.setAttribute(
  //     "action",
  //     `${proxy}${encodeURIComponent(node.getAttribute("action"))}`
  //   );
  // }
  // proxy regular HTML links
  if (node.hasAttribute("src")) {
    // console.log(node);
    node.setAttribute("src", `${proxy}${node.getAttribute("src")}`);
  }
});
/**
 * Component for showing Component "Blog Single Posts" of the site.
 *
 * @component
 * @version: 1.0.1
 **/
const BlogSinglePost = (props) => {
  const { data: postData, sharedURL } = props;
  const { title, content, description, author } = postData;
  // const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  // const [headings, setHeadings] = useState([]);

  // console.log(props);
  // console.log(new Date(postData.updatedDate));
  const daysFromToday = daysAgo(new Date(postData.updatedDate));

  const useLinkClickHandlers = (ref) => {
    const navigate = useNavigate();

    useEffect(() => {
      if (!ref.current) return;

      const links = ref.current.querySelectorAll("img");
      links.forEach((link) => link.addEventListener("click", handleLinkClick));

      // console.log(ref);

      return () => {
        links.forEach((link) =>
          link.removeEventListener("click", handleLinkClick)
        );
      };
    }, [navigate, ref]);
  };

  const useTableOfContent = (ref) => {
    // const navigate = useNavigate();

    useEffect(() => {
      if (!ref.current) return;

      const headings = document.querySelectorAll("h2, h3, h4");
      const elements = Array.from(headings).map((elem) => ({
        id: elem.id,
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1)),
      }));

      // const elements = Array.from(document.querySelectorAll("h2, h3, h4")).map(
      //   (elem) => ({
      //     text: elem.innerText,
      //   })
      // );
      // setHeadings(elements);
      console.log(elements);
    }, [ref]);
  };

  const HtmlContent = (props) => {
    const { html } = props;
    const ref = useRef(null);

    const sanitizedContent = useMemo(() => {
      return {
        __html: DOMPurify.sanitize(html),
      };
    }, [html]);

    useLinkClickHandlers(ref);
    useTableOfContent(ref);

    return <div dangerouslySetInnerHTML={sanitizedContent} ref={ref} />;
  };

  const handleLinkClick = (e) => {
    e.preventDefault();

    // console.log(e);

    setIsOpen(true);
    // const link = event.currentTarget as HTMLAnchorElement
    // const href = link.getAttribute('href')
    // const target = link.getAttribute('target')
    // const url = new URL(href || '', window.location.origin)
    // const isInternalLink = url.origin === window.location.origin
    // const isOpenedInSameWindow = !target || target === '_self'
    // const isLeftButtonClick = event.button === 0
    // // E.g. Ctrl-clicking a link opens it in a new tab
    // // so let the browser handle such clicks
    // const isModifierKeyPressed =
    //   event.altKey || event.ctrlKey || event.metaKey || event.shiftKey
    // if (
    //   isInternalLink &&
    //   isOpenedInSameWindow &&
    //   isLeftButtonClick &&
    //   !isModifierKeyPressed
    // ) {
    //   event.preventDefault()
    //   navigate(url.pathname + url.search + url.hash)
    // }
  };

  const renderImages = () => {
    return postData.images.map((item) => ({
      src: `${BLOG_API_URI}/posts/imagedb/${item.name}`,
    }));
  };

  useEffect(() => {
    console.log(index);
  }, [index]);

  if (!postData) return null;

  return (
    <div className="single-blog blog-style-2 blog_details_section">
      <div className="details_image">
        <div className="blog-img">
          <ProgressiveImg2
            hightSrc={`${BLOG_API_URI}/posts/imagedb/${postData?.imageURL}`}
            lowSrc={placeholderSrc}
            alt={postData.imageAlt || "PsyWealth.com"} // {data?.imageAlt || data.title}
            loading="lazy"
            className="img-fluid rounded-4"
          />
        </div>
      </div>

      <div className="blog-details-content">
        <h1 className="h2 my-4">{title}</h1>

        {/* Blog Metadata */}
        <BlogPostMetadata data={postData} />

        {/* Blog Content */}
        <div className="site-content blog-content">
          <div className="blog-content__description">
            <p className="fst-italic">{description}</p>
          </div>

          <HtmlContent html={content} />
        </div>

        {/* Blog Author */}
        <div className="d-sm-flex justify-content-center justify-content-sm-between align-items-center text-start mt-5">
          <div className="d-flex justify-content-center align-items-center mb-5 mb-sm-0">
            <div className="avatar flex-shrink-0">
              {author.slug === "lovkova" ? (
                <img
                  className="avatar-img rounded-circle"
                  src={require("assets/images/avatar/05.webp")}
                  alt="avatar"
                  width="60"
                  height="60"
                />
              ) : (
                <img
                  className="avatar-img rounded-circle"
                  src={`${APP_API_URI}/psychologists/psychologist/v2/profile/${author.slug}/image`}
                  alt="avatar"
                  width="60"
                  height="60"
                />
              )}
            </div>
            <div className="ms-2">
              <h6 className="mb-0">
                <Link to={`/therapists/${author.slug}`}>
                  {author?.username}
                </Link>
              </h6>
              <small>{`${daysFromToday} дней`}</small>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <span className="text-secondary opacity-3 mx-3">|</span>
            <span className="text-secondary">2 min read</span>
          </div>
        </div>

        {/* Blog Tags */}
        <BlogPostTags data={postData} sharedUrl={sharedURL} />
      </div>

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={renderImages()}
          index={index}
          setIndex={setIndex}
        />
      )}
    </div>
  );
};

export default BlogSinglePost;
