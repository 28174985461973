import { PropTypes } from "prop-types";

import Icon1 from "assets/images/new/shape_leaf_left_top.svg";

import { useNavigate } from "react-router-dom";
import HeroControl from "./HeaderControl";
import "./Section.style.scss";

// NOTE :

const msgPrimary = "Заполнить анкету";
const msgSecondary = "Бонус за регистрацию";

/**
 * Component section content "Hero (style 5)" of the site.
 *
 * @component
 * @version: 1.0.1
 **/
const SectionContentStyle5 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    innerRef,
    onSubmit,
    onRegister,
    ...otherProps
  } = props;
  const navigate = useNavigate();
  console.log(data);

  const heroControl = {
    primary: { title: msgPrimary },
    secondary: { title: msgSecondary },
  };

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  const handlerRegister = () => {
    navigate("/psychologist/lk/auth");
    onRegister && onRegister();
  };

  return (
    <div className="hero_section decoration_wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero_content_wrap">
              <h2 className="heading_subtitle">{data.subtitle}</h2>
              <h1 className="heading_text">{data.title}</h1>
              <p className="heading_description">{data.description}</p>

              <HeroControl
                data={heroControl}
                cn="mt-8"
                onClickPrimary={handlerSubmit}
                onClickSecondary={handlerRegister}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero_image_wrap">
              <img
                src={
                  data.image.src ||
                  require("assets/images/new/main-slider-three-img-2.webp")
                }
                alt={data.image || "PsyWealth – сервис психологической помощи"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_blur_shadow"></div>
      <div className="decoration_item shape_leaf">
        <img
          src={Icon1}
          alt="Shape Leaf - PsyWealth – сервис психологической помощи"
        />
      </div>
    </div>
  );
};

SectionContentStyle5.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle5;
