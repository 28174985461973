import { BLOCK_SPAM_MS } from "config/config";
//import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { registerSubscription } from "services/events.service";
import { _debug } from "utils";

// NOTE : https://blog.logrocket.com/using-dangerouslysetinnerhtml-react-application/

const msgTitle = "Подписаться";
const msgSubmitted = "Вы успешно подписаны";
const msgSubscribed = "Вы успешно подписаны";
const msgFormEmailPlaceholder = "Ваша почта";
const msgFormEmailRequired = "Требуется ввести адрес электронной почты"; // "Email is required",

/**
 * Component for showing Component "Subscription From" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SubscriptionForm = (props) => {
  const { id, cn, innerRef, ...otherProps } = props;

  const {
    methods,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //const gaEventTracker = useAnalyticsEventTracker("User subscribe");

  const handleSubscriptionSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    setError(null);
    try {
      //gaEventTracker("Subscribe");

      const response = await registerSubscription({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  const onSubmit = (data) => {
    console.log("onSubmit", data);

    setIsSubmitted(true);

    // User subscribe
    handleSubscriptionSubmit(data);
    reset();
  };

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) setIsSubmitted(false);
      _debug(isSubmitted);
    }, BLOCK_SPAM_MS);
  }, [isSubmitted]);

  return (
    <FormProvider {...methods}>
      <Form className={classNames("", cn)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group
          className="input-group has-feedback"
          data-toggle="tooltip"
          title="Hooray!"
        >
          <Form.Control
            type="text"
            name="email"
            id="email"
            placeholder={msgFormEmailPlaceholder}
            isInvalid={!!errors.email}
            className="focus-shadow-none border-0 me-1"
            {...register("email", {
              required: { msgFormEmailRequired },
              validate: {
                maxLength: (v) =>
                  v.length <= 50 ||
                  "The email should have at most 50 characters",
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            })}
            aria-invalid={errors?.email ? "true" : "false"}
          />

          <button
            type="submit"
            disabled={isSubmitted}
            aria-label="Submit"
            className="btn btn-dark rounded-2"
          >
            {msgTitle}
          </button>

          <Form.Control.Feedback
            type="invalid"
            tooltip
            style={{ width: "100%", wordBreak: "break-all", top: "-120%" }}
          >
            {errors.email && <small>{errors.email.message}</small>}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>

      {errors &&
        errors.length > 0 &&
        errors.map((item, index) => (
          <p
            className="text-warning"
            style={{ textAlign: "center" }}
            key={index}
          >
            <small>{item}</small>
          </p>
        ))}

      {error && (
        <p className="text-warning text-center">
          <small>{error}</small>
        </p>
      )}
      {success && (
        <p className="text-success text-center">
          <small>{msgSubmitted}</small>
        </p>
      )}
    </FormProvider>
  );
};

SubscriptionForm.propTypes = {
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default SubscriptionForm;
