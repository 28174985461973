import React from "react";
import LayoutPsychologistProfile from "./PsychologistProfile.layout";

const PsychologistAvailableTimingsLazy = React.lazy(() =>
  import(
    "./components/ProfileTabs/ProfileTabAvailableTimings/PsychologistAvailableTimings"
  )
);
const PsychologistProfileEducationEditLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabEducation/EducationEdit")
);
const PsychologistProfileTabAppointmentsLazy = React.lazy(() =>
  import(
    "./components/ProfileTabs/ProfileTabAppointments/ProfileTabAppointments"
  )
);
const PsychologistProfileTabBioLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabBio/ProfileTabBio")
);
const PsychologistProfileTabDeleteLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabDelete/ProfileTabDelete")
);
const PsychologistProfileTabEducationLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabEducation/ProfileTabEducation")
);
const PsychologistProfileTabOrdersLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabOrders/ProfileTabOrders")
);
const PsychologistProfileTabPaymentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabPayments/ProfileTabPayments")
);
const PsychologistProfileTabProductLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabProduct/ProfileTabProduct")
);
const PsychologistProfileTabProfileLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabProfile/ProfileTabProfile")
);
const PsychologistProfileTabSettingsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabSettings/ProfileTabSettings")
);
const PsychologistProfileTabWishlistLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabWishlist/ProfileTabWishlist")
);

export {
  LayoutPsychologistProfile,
  PsychologistAvailableTimingsLazy,
  PsychologistProfileEducationEditLazy,
  PsychologistProfileTabAppointmentsLazy,
  PsychologistProfileTabBioLazy,
  PsychologistProfileTabDeleteLazy,
  PsychologistProfileTabEducationLazy,
  PsychologistProfileTabOrdersLazy,
  PsychologistProfileTabPaymentsLazy,
  PsychologistProfileTabProductLazy,
  PsychologistProfileTabProfileLazy,
  PsychologistProfileTabSettingsLazy,
  PsychologistProfileTabWishlistLazy,
};
