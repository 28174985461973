export const dataSite = {
  main: {
    address: "Москва, 123100, Пресненская наб. 12, Город Столиц",
    phone: "+7(925)995-87-90",
    phoneLink: "tel:+79259958790",
    email: "info@psywealth.com",
    emailLink: "mailto:info@psywealth.com",
    title: "psywealth",
    subtitle: "psywealth.com",
    description: "PsyWealth - сервис для оказания психологической помощи",
    copyrightYear: "2023-2024",
    copyrightText: "psywealth.com",
    copyrightDescription:
      "Все права защищены. Полное или частичное копирование материалов запрещено. Используем cookies для корректной работы сайта, персонализации пользователей и других целей, предусмотренных политикой обработки персональных данных.",
    wh: "Открыто: 24 часа / 7 дней",
    wh1: "Время работы офиса: 10:00 – 17:30",
    whStart: "10:00",
    whClose: "17:30",
    name: "ИП Ловков Александр Вячеславович",
    INN: "7801649294",
    KPP: "780101001",
    OGRNIP: "323695200000413",
  },
  design: {
    title: "Простые Изобретения, ООО",
    link: "https://simpleinventions.tech",
  },
  owner: {
    title: "123123",
  },
  countdown: {
    title: "PsyWealth — психолог рядом",
    subtitle:
      "Сервис поиска и подбора психологов и психотерапевтов для психологической помощи",
    datetime: ["дней", "часов", "минут", "секунд"],
    description:
      "Подберем специалиста, который услышит, поймет, определит проблемы, поможет поставить цель терапии и приведет к результату",
  },
  copyright: {
    copyStatement:
      "Источник: https://psywealth.com © 2024-2025, PsyWealth — забота о ментальном здоровье, психологи и психотерапевты онлайн",
  },
};
