import React from "react";
import {
  BlogLayout,
  BlogSinglePostPage,
  PostsByCategoryPage,
  PostsByTagPage,
  PostsListPage,
} from "./Blog";
import { PageContacts } from "./Contacts";
import { PageCountDown } from "./CountDown";
import { PageCourses } from "./Courses";
import { PageCustomerEntry } from "./CustomerForm";
import { PageDemo } from "./Demo";
import { PageFAQ } from "./FAQ";
import { PageFreePsychologicalHelp } from "./FreeHelp";
import { PageGroupPsychotherapy } from "./GroupPsychotherapy";
import { PageHome } from "./Home";
import { PageNotFound } from "./NotFound";
import { PageOurTeam } from "./OurTeam";
import { PagePartnersPsychologist } from "./Partnership";
import {
  LayoutPoliciesPage,
  PagePaymentTerms,
  PagePolicies,
  PagePrivacyTerms,
  PageTermsAndConditions,
} from "./Policies";
import { LayoutPsychologistProfile } from "./Psychologist";
import { PageSearch } from "./Search";
import { PageServices } from "./Services";
import { PageTests } from "./Tests";
import { PageTypography } from "./Typography";

const LayoutPsychologistProfileLazy = React.lazy(() =>
  import("./Psychologist/PsychologistProfile.layout")
);
const LayoutPoliciesPageLazy = React.lazy(() =>
  import("./Policies/PoliciesPageLayout")
);
const BlogLayoutLazy = React.lazy(() => import("./Blog/BlogLayout"));

const PageHomeLazy = React.lazy(() => import("./Home/Home.page"));
const PostsListPageLazy = React.lazy(() => import("./Blog/PostsList_v1.page"));
const PostsListPageLazyV2 = React.lazy(() =>
  import("./Blog/PostsList_v2.page")
);
const BlogSinglePostPageLazy = React.lazy(() =>
  import("./Blog/BlogSinglePost.page")
);
const PostsByCategoryPageLazy = React.lazy(() =>
  import("./Blog/PostsByCategory_v1.page")
);
const PostsByCategoryPageLazyV2 = React.lazy(() =>
  import("./Blog/PostsByCategory_v2.page")
);
const PostsByTagPageLazy = React.lazy(() =>
  import("./Blog/PostsByTag_v1.page")
);
const PostsByTagPageLazyV2 = React.lazy(() =>
  import("./Blog/PostsByTag_v2.page")
);
const PageContactsLazy = React.lazy(() => import("./Contacts/Contacts.page"));
const PageCountDownLazy = React.lazy(() =>
  import("./CountDown/CountDown.page")
);
const PageCoursesLazy = React.lazy(() => import("./Courses/Courses.page"));
const PageCustomerEntryLazy = React.lazy(() =>
  import("./CustomerForm/CustomerEntry.page")
);
const LayoutCustomerProfileLazy = React.lazy(() =>
  import("./Customer/CustomerProfile.layout")
);
const PageDemoLazy = React.lazy(() => import("./Demo/Demo.page"));
const PageFAQLazy = React.lazy(() => import("./FAQ/FAQ.page"));
const PageFreePsychologicalHelpLazy = React.lazy(() =>
  import("./FreeHelp/FreeHelp.page")
);
const PageNotFoundLazy = React.lazy(() => import("./NotFound/NotFound.page"));
const PageOurTeamLazy = React.lazy(() => import("./OurTeam/OurTeam.page"));
const PageGroupPsychotherapyLazy = React.lazy(() =>
  import("./GroupPsychotherapy/GroupPsychotherapy.page")
);
const PagePartnersPsychologistLazy = React.lazy(() =>
  import("./Partnership/PartnersPsychologist.page")
);
const PagePaymentTermsLazy = React.lazy(() =>
  import("./Policies/PaymentTerms.page")
);
const PagePrivacyTermsLazy = React.lazy(() =>
  import("./Policies/PrivacyTerms.page")
);
const PagePoliciesLazy = React.lazy(() => import("./Policies/Policies.page"));
const PageSearchLazy = React.lazy(() => import("./Search/Search.page"));
const PageServicesLazy = React.lazy(() => import("./Services/Services.page"));
const PageTermsAndConditionsLazy = React.lazy(() =>
  import("./Policies/TermsAndConditions.page")
);
const PageTestsLazy = React.lazy(() => import("./Tests/Tests.page"));
const PageTypographyLazy = React.lazy(() =>
  import("./Typography/Typography.page")
);

export {
  BlogLayout,
  BlogLayoutLazy,
  BlogSinglePostPage,
  BlogSinglePostPageLazy,
  LayoutCustomerProfileLazy,
  LayoutPoliciesPage,
  LayoutPoliciesPageLazy,
  LayoutPsychologistProfile,
  LayoutPsychologistProfileLazy,
  PageContacts,
  PageContactsLazy,
  PageCountDown,
  PageCountDownLazy,
  PageCourses,
  PageCoursesLazy,
  PageCustomerEntry,
  PageCustomerEntryLazy,
  PageDemo,
  PageDemoLazy,
  PageFAQ,
  PageFAQLazy,
  PageFreePsychologicalHelp,
  PageFreePsychologicalHelpLazy,
  PageGroupPsychotherapy,
  PageGroupPsychotherapyLazy,
  PageHome,
  PageHomeLazy,
  PageNotFound,
  PageNotFoundLazy,
  PageOurTeam,
  PageOurTeamLazy,
  PagePartnersPsychologist,
  PagePartnersPsychologistLazy,
  PagePaymentTerms,
  PagePaymentTermsLazy,
  PagePolicies,
  PagePoliciesLazy,
  PagePrivacyTerms,
  PagePrivacyTermsLazy,
  PageSearch,
  PageSearchLazy,
  PageServices,
  PageServicesLazy,
  PageTermsAndConditions,
  PageTermsAndConditionsLazy,
  PageTests,
  PageTestsLazy,
  PageTypography,
  PageTypographyLazy,
  PostsByCategoryPage,
  PostsByCategoryPageLazy,
  PostsByCategoryPageLazyV2,
  PostsByTagPage,
  PostsByTagPageLazy,
  PostsByTagPageLazyV2,
  PostsListPage,
  PostsListPageLazy,
  PostsListPageLazyV2,
};
