import { SITE } from "config/config";

const dataPageTitle =
  "Психологи и психотерапевты онлайн на PsyWealth — онлайн-сервис психологической помощи";
// Психологи России - запись на консультацию, выбор среди 480 проверенных психологов
//  "Сервис онлайн-консультаций «PsyWealth.com», консультации с психологом или психотерапевтом - чат, видео, аудио",
const dataPageDescription =
  "Сайт психологов — онлайн-сервис психотерапии | Подбор своего психотерапевта из проверенных | Цена психологической консультации от 2850 ₽ | Простой поиск ✅ Профессиональная помощь ✅";
//  Психологи России, собранные на одном сайте. Если вам нужна помощь психолога, агрегатор psy-mk.ru поможет выбрать и записать к подходящему именно вам специалисту.

// "Cпециализированные решения по созданию, комплексному оснащению и наполнению электронных архивов, внедрению передовых информационных технологий и решений в области электронного документооборота. Попробуйте бесплатно и оцените удобство и простату наших решений.",
// High Performance Object Storage, Multi-Cloud, Hybrid Cloud, Object Storage, Object Storage for AI, Cloud Storage, S3 Compatible, Golang, AWS S3, Amazon S3, Open Source Cloud Storage, Ceph, OpenStack Swift, Microsoft Azure Blobstore, Google Cloud Storage, Cloud Foundry Blobstore, Cloud Foundry Bosh, Artifact Storage, DC/OS, Kubernetes, Docker, Container Storage, Blobstore, NAS, Gluster, GlusterFS, PACS, VNA, Archive, Secondary Storage, Backup Storage, Snapshots Hybrid, OnPrem, Private Cloud
// <meta name="description" content="MinIO's High Performance Object Storage is Open Source, Amazon S3 compatible, Kubernetes Native and is designed for cloud native workloads like AI.">
const dataPageAuthor = SITE.AUTHOR;

const dataMetaOGTitle =
  "Психолог или психотерапевт онлайн. Доступная психологическая помощь и консультирование по видеочату, аудиочату и в мессенджерах." ||
  dataPageTitle;
const dataMetaOGDescription =
  "Вам поможет специалист с дипломом психолога и опытом консультирования больше 5 лет. Обеспечиваем анонимность общения." ||
  dataPageDescription;
const dataMetaOGImage = "https://psywealth.com/images/OG/main_1200x640.png";
const dataMetaOGImageAlt = "Найдите своего психолога или психотерапевта";
const dataMetaOGLocale = SITE.LOCALE;
const dataMetaOGSiteName = SITE.NAME;

const dataMetaBlogPage_CreateDate = "2023-10-01T07:00:00+00:00";
const dataMetaBlogPage_UpdateDate = "2024-12-10T07:59:00+00:00";

const dataMetaTWImage = dataMetaOGImage;
const dataMetaTWCreator = SITE.URL;
const dataMetaTWDomain = SITE.URL;

export const dataMetaGroupPsychotherapyPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: dataPageAuthor,
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataMetaOGTitle,
    },
    {
      property: "og:description",
      content: dataMetaOGDescription,
    },
    {
      property: "og:url",
      content: "",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaOGImage,
    },
    {
      property: "og:image:alt",
      content: dataMetaOGImageAlt,
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "640",
    },
    {
      property: "og:locale",
      content: dataMetaOGLocale,
    },
    {
      property: "og:site_name",
      content: dataMetaOGSiteName,
    },
    // {
    //   property: "article:published_time",
    //   content: dataMetaBlogPage_CreateDate,
    // },
    // {
    //   property: "article:modified_time",
    //   content: dataMetaBlogPage_UpdateDate,
    // },
  ],
  TW: [
    {
      name: "twitter:title",
      content:
        "Онлайн психолог | Подбор психолога и психотерапевта онлайн. Консультирование по видеочат, аудиочат и в мессенджерах.",
    },
    {
      name: "twitter:description",
      content:
        "psywealth.com - Психолог онлайн консультации. Психологическая помощь онлайн - чат, видео, аудио.",
    },
    { property: "twitter:card", content: "summary_large_image" },
    // { property: "twitter:label1", content: "Примерное время для чтения" },
    // { property: "twitter:data1", content: "10 минуты" },
    {
      name: "twitter:image:src",
      content: dataMetaTWImage,
    },
    {
      name: "twitter:creator",
      content: dataMetaTWCreator,
    },
    {
      name: "twitter:domain",
      content: dataMetaTWDomain,
    },
  ],
};
