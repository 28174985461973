import classNames from "classnames";
import { SectionRelatedPostsLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { Loading } from "components/utils";
import { SEO } from "components/utils/Analytics";
import { PageSection } from "core/components";
import { dataBlogPage } from "data";
import { Fragment, Suspense, useState } from "react";
import { Row } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import { BlogBoxCategories, BlogBoxSocial, BlogBoxTopTags } from "./components";

// NOTE : https://preview.uideck.com/items/cryptoland/
// NOTE : https://github.com/CodeCompleteYT/react-search-bar/blob/main/src/components/SearchResult.jsx

const dataRelatedPosts = {
  title: "Самое читаемое в блоге",
  subtitle: "Самое читаемое в блоге",
  description: "Самое читаемое в блоге",
};

const SectionContent = (props) => {
  const { data, changeBannerData } = props;

  return (
    <Fragment>
      <Row>
        {/* Blog posts */}
        <div className="col-lg-9 col-xl-8 pe-lg-4 pe-xl-0">
          <div className="left-side-wrapper">
            <Suspense fallback={<Loading />}>
              <Outlet context={{ changeBannerData }} />
            </Suspense>
          </div>
        </div>
        {/* Sidebar (off-canvas on screens < 992px) */}
        <aside className="col-lg-3 offset-xl-1">
          <div className="sidebar-wrapper">
            <Suspense fallback={<Loading />}>
              <BlogBoxCategories />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <BlogBoxTopTags />
            </Suspense>
            <BlogBoxSocial />
          </div>
        </aside>
      </Row>
    </Fragment>
  );
};

/**
 * Component for showing Page "Blog Layout" of the site.
 *
 * @component
 */
const BlogLayout = (props) => {
  const { cn = "pt-80 pb-80", ...otherProps } = props;
  const { slug } = useParams();
  const [bannerData, setBannerData] = useState({ title: "", slug: "" });

  return (
    <Fragment>
      <SEO data={dataBlogPage.meta}>
        {/* Only canonicalize pages which are a duplicate or are near-identical. */}
        {/* {slug ? (
          <link rel="canonical" href={`https://psywealth.com/blog/${slug}`} />
        ) : (
          <link rel="canonical" href="https://psywealth.com/blog" />
        )} */}
        {/* <script type="application/ld+json">{JSON.stringify(ldJson)}</script> */}
      </SEO>
      <main className="page-blog page-content" id="top">
        <Banner2
          data={dataBlogPage}
          isSingleBlog={slug}
          bannerData={bannerData}
        />
        <PageSection
          id="blog-post-list"
          cn={classNames("blog-section blog-content section-space-lg", cn)}
        >
          <div className="container">
            <SectionContent
              data={dataBlogPage}
              changeBannerData={setBannerData}
            />
          </div>
        </PageSection>
        <Suspense fallback={<Loading />}>
          <SectionRelatedPostsLazy data={dataRelatedPosts} type={"style2"} />
        </Suspense>
      </main>
    </Fragment>
  );
};

export default BlogLayout;

/*

  const {
    data: postMetadata,
    isLoading: isLoadingPostMetadata,
    isError: isErrorPostMetadata,
    error: errorPostMetadata,
  } = useGetMetaBlogPost(slug);
  const [meta, setMeta] = useState({});

  // console.log("!!!", slug);

  const isLoading = isLoadingPostMetadata;
  const isError = isErrorPostMetadata;
  const errors = [errorPostMetadata];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    const createMeta = async () => {
      try {
        const metadataObj = postMetadata?.toHashMap("type");
        // 1. check data valid
        // const metaLD = JSON.stringify(metadataObj?.LD.content);
        const metaMain = JSON.parse(metadataObj?.Main.content);
        const metaOG = JSON.parse(metadataObj?.OG.content);
        const metaTW = JSON.parse(metadataObj?.TW.content);
        console.log(metaMain, metaOG);

        const metaNew = { ...dataBlogPage.meta, ...metaMain };

        // if (meta.OG && meta.OG.length > 0)
        // metaNew.OG = [...metaNew.OG, ...metaOG];
        const map1 = new Map(
          [...metaNew.OG, ...metaOG].map((obj) => [obj.property, obj])
        );
        metaNew.OG = Array.from(map1.values());

        // if (meta.TW && meta.TW.length > 0)
        // metaNew.TW = [...metaNew.TW, ...metaTW];
        const map2 = new Map(
          [...metaNew.TW, ...metaTW].map((obj) => [obj.property, obj])
        );
        metaNew.TW = Array.from(map2.values());

        _debug("METADATA FULL:", metaNew);
        setMeta(metaNew);
      } catch (err) {
        console.error(err);
        setMeta({});
      }
    };

    if (postMetadata) {
      _debug("METADATA:", postMetadata);

      createMeta();
    }
  }, [postMetadata]);

  // Show a loading message while data is fetching
  if (isLoading) {
    //return <Spinner hidden={!isLoading} />;
  }

  if (isLoadingPostMetadata && errorPostMetadata?.status === 204) {
    //   return <div className="error">Нет платежей</div>;
  }

  // console.log(postMetadata);
*/
