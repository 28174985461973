import { dataMetaAuthPage } from "./meta/dataMetaAuthPage";
import { dataMetaBlankPage } from "./meta/dataMetaBlankPage";
import { dataMetaBlogPage } from "./meta/dataMetaBlogPage";
import { dataMetaContactsPage } from "./meta/dataMetaContactsPage";
import { dataMetaCountDownPage } from "./meta/dataMetaCountDownPage";
import { dataMetaCoursesPage } from "./meta/dataMetaCoursesPage";
import { dataMetaCustomerEntryPage } from "./meta/dataMetaCustomerEntryPage";
import { dataMetaCustomerProfilePage } from "./meta/dataMetaCustomerProfilePage";
import { dataMetaDemoPage } from "./meta/dataMetaDemoPage";
import { dataMetaFAQPage } from "./meta/dataMetaFAQPage";
import { dataMetaFreeHelpPage } from "./meta/dataMetaFreeHelpPage";
import { dataMetaGlossaryPage } from "./meta/dataMetaGlossaryPage";
import { dataMetaGroupPsychotherapyPage } from "./meta/dataMetaGroupPsychotherapyPage";
import { dataMetaHomePage } from "./meta/dataMetaHomePage";
import { dataMetaMaintenancePage } from "./meta/dataMetaMaintenancePage";
import { dataMetaNotFoundPage } from "./meta/dataMetaNotFoundPage";
import { dataMetaOurTeamPage } from "./meta/dataMetaOurTeamPage";
import { dataMetaPartnershipPage } from "./meta/dataMetaPartnershipPage";
import { dataMetaPoliciesPage } from "./meta/dataMetaPoliciesPage";
import { dataMetaPolicyPaymentPage } from "./meta/dataMetaPolicyPaymentPage";
import { dataMetaPolicyPrivacyPage } from "./meta/dataMetaPolicyPrivacyPage";
import { dataMetaPolicyTermsPage } from "./meta/dataMetaPolicyTermsPage";
import { dataMetaPsychologistProfilePage } from "./meta/dataMetaPsychologistProfilePage";
import { dataMetaSearchPage } from "./meta/dataMetaSearchPage";
import { dataMetaServicesPage } from "./meta/dataMetaServicesPage";
import { dataMetaTestsPage } from "./meta/dataMetaTestsPage";
import { dataMetaTherapistsPage } from "./meta/dataMetaTherapistsPage";

// checked

export const dataHomePage = {
  title: "Главная страница",
  subtitle: "Главная страница",
  description: "Главная страница",
  meta: dataMetaHomePage,
};

export const dataBlogPage = {
  title: "Блог",
  subtitle: "Блог",
  description: "Блог",
  meta: dataMetaBlogPage,
};

export const dataPartnershipPage = {
  title: "Партнёрская программа",
  subtitle: "Партнёрская программа",
  description: "Партнёрская программа",
  meta: dataMetaPartnershipPage,
  extraData: null,
};

export const dataFreeHelpPage = {
  title: "Бесплатная психологическая помощь",
  subtitle: "Консультация психолога может понадобиться каждому.",
  description:
    "Если у вас нет возможности обратиться к психологу за деньги, существуют специализированные центры, где любой человек может получить бесплатную психологическую помощь.",
  meta: dataMetaFreeHelpPage,
  metaLdJson: null,
};

export const dataServicesPage = {
  title: "Наши услуги",
  subtitle: "Наши услуги",
  description: "Наши услуги",
  meta: dataMetaServicesPage,
  extraData: {
    title: "Наши услуги",
    subtitle: "Наши услуги",
    description: "",
  },
};

export const dataOurTeamPage = {
  title: "Наша команда",
  subtitle: "Наша команда",
  description: "Наша команда",
  meta: dataMetaOurTeamPage,
};

export const dataCoursesPage = {
  title: "Наши курсы и тренинги",
  subtitle: "Наши курсы и тренинги",
  description: "Наши курсы и тренинги",
  meta: dataMetaCoursesPage,
};

export const dataTestsPage = {
  title: "Психологические тесты",
  subtitle: "Психологические тесты",
  description: "Психологические тесты",
  meta: dataMetaTestsPage,
};

export const dataFAQPage = {
  title: "Част задаваемые вопросы и ответы (FAQ)",
  subtitle: "Свяжитесь с нами, если у вас есть вопрос!",
  description: "Свяжитесь с нами, если у вас есть вопрос!",
  meta: dataMetaFAQPage,
};

export const dataContactsPage = {
  title: "Связаться с нами",
  subtitle: "Готовы начать работу",
  description: "Напишите нам",
  meta: dataMetaContactsPage,
  extraData: {
    title: "Напишите нам",
    subtitle: "Напишите нам",
    description: "",
  },
};

export const dataGroupPsychotherapyPage = {
  title: "Групповая психотерапия",
  subtitle: "Групповая психотерапия",
  description: "Групповая психотерапия",
  meta: dataMetaGroupPsychotherapyPage,
  extraData: {
    title: "Напишите нам",
    subtitle: "Напишите нам",
    description: "",
  },
};

export const dataCustomerEntryPage = {
  title: "Анкета для консультации",
  subtitle: "Анкета для консультации",
  description: "Анкета для консультации",
  meta: dataMetaCustomerEntryPage,
};

export const dataSearchPage = {
  title: "Поиск по сайту",
  subtitle: "Поиск",
  description: "Введите фразу в поле для поиска.",
  meta: dataMetaSearchPage,
};

export const dataPoliciesPage = {
  title: "Правовые документы",
  subtitle: "Правовые документы",
  description: "Правовые документы",
  meta: dataMetaPoliciesPage,
};

export const dataPolicyTermsPage = {
  title: "Политики",
  subtitle: "Политики конфиденциальности и обработки персональных данных",
  description: "Политики конфиденциальности и обработки персональных данных",
  meta: dataMetaPolicyTermsPage,
};

export const dataPolicyPrivacyPage = {
  title: "Проведение оплат и расчетов",
  subtitle: "Политика проведения оплат и расчетов",
  description: "Политика проведения оплат и расчетов",
  meta: dataMetaPolicyPrivacyPage,
};

export const dataPolicyPaymentPage = {
  title: "Проведение оплат и расчетов",
  subtitle: "Политика проведения оплат и расчетов",
  description: "Политика проведения оплат и расчетов",
  meta: dataMetaPolicyPaymentPage,
};

export const dataCountDownPage = {
  title: "",
  subtitle: "",
  description: "",
  meta: dataMetaCountDownPage,
};

// unchecked

export const dataDemoPage = {
  title: "Демо страница",
  subtitle: "Демо страница",
  description: "Демо страница",
  meta: dataMetaDemoPage,
};

export const dataAuthPage = {
  title: "Hello World",
  subtitle: "",
  description: "",
  meta: dataMetaAuthPage,
};

export const dataQuizPage = {
  title: "Психологические тесты",
  subtitle: "Психологические тесты",
  description: "Психологические тесты",
  meta: dataMetaTestsPage,
};

export const dataTypographyPage = {
  title: "Typography page",
  subtitle: "Typography page",
  description: "Typography page",
  meta: dataMetaBlankPage,
};

export const dataNotFoundPage = {
  title: "Страница не найдена - 404",
  subtitle: "Похоже, запрашивая страница больше не существует...",
  description:
    "Страница, которую вы ищете, могла быть удалена, если бы она была имя изменено или временно недоступно.",
  meta: dataMetaNotFoundPage,
};

export const dataMaintenancePage = {
  title: "We are Under Maintenance",
  subtitle: "Will be Back Soon!",
  description: "Will be Back Soon!",
  meta: dataMetaMaintenancePage,
};

export const dataTherapistsPage = {
  title: "Наши психологи",
  subtitle: "Наши психологи",
  description: "Наши психологи",
  meta: dataMetaTherapistsPage,
};

export const dataGlossaryPage = {
  title: "Глоссарий",
  subtitle: "Глоссарий",
  description:
    "Словарь психологических терминов, применяющимися в области психологии, их расшифровка и значение.",
  meta: dataMetaGlossaryPage,
  extraData: null,
};

export const dataPsychologistProfilePage = {
  title: "Профиль",
  subtitle: "Профиль",
  description: "Профиль",
  meta: dataMetaPsychologistProfilePage,
};

export const dataCustomerProfilePage = {
  title: "Профиль",
  subtitle: "Профиль",
  description: "Профиль",
  meta: dataMetaCustomerProfilePage,
};

export const dataTopPostsByAuthor = {
  title: "Статьи автора",
  subtitle: "Статьи автора",
  description: "Статьи автора",
  meta: null,
};
