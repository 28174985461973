import { Fragment, Suspense } from "react";
import { Row } from "react-bootstrap";
//import { profileImage } from "services/actions";
import { Loading } from "components/utils";
import { PageSection } from "core/components";
import { BsGear, BsPerson } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { ProfileSidebar } from "../../Customer/components/core/ProfileSidebar";

// NOTE :

const sidebarMenu = [
  { title: "Профиль", path: "/psychologist/profile", icon: <BsPerson /> },
  { title: "Биография", path: "/psychologist/profile/bio", icon: <BsGear /> },
  {
    title: "Образование",
    path: "/psychologist/profile/education",
    icon: <BsGear />,
  },
  {
    title: "Услуги и сервисы",
    path: "/psychologist/profile/product",
    icon: <BsGear />,
  },
  {
    title: "Расписание",
    path: "/psychologist/profile/available-timings",
    icon: <BsGear />,
  },
  {
    title: "Записи",
    path: "/psychologist/profile/appointments",
    icon: <BsGear />,
  },

  { title: "Заказы", path: "/psychologist/profile/orders", icon: <BsGear /> },
  {
    title: "Платежи",
    path: "/psychologist/profile/payments",
    icon: <BsGear />,
  },
  {
    title: "Избранное",
    path: "/psychologist/profile/wishlist",
    icon: <BsGear />,
  },
  {
    title: "Настройки",
    path: "/psychologist/profile/settings",
    icon: <BsGear />,
  },
  {
    title: "Удалить профиль",
    path: "/customer/profile/account-delete",
    icon: <BsGear />,
  },
  {
    title: "Выйти",
    path: "/customer/profile/sign-out",
    icon: <FaSignOutAlt />,
  },
];

const SectionContent = (props) => {
  const { data } = props;
  const { profile } = data;

  // console.log("eeee", props);

  return (
    <Fragment>
      <Row>
        {/*<!-- Sidebar START -->*/}
        <div className="col-lg-4 col-xl-3">
          <Suspense fallback={<Loading />}>
            <ProfileSidebar data={{ profile, menu: sidebarMenu }} />
          </Suspense>
        </div>
        {/*<!-- Sidebar END -->*/}

        {/*<!-- Main content START -->*/}
        <div className="col-lg-8 col-xl-9">
          <Suspense fallback={<Loading />}>
            <Outlet context={{ profile }} />
          </Suspense>
        </div>
        {/*<!-- Main content END -->*/}
      </Row>
    </Fragment>
  );
};

/**
 * Component for showing section "Psychologist Profile" of the site.
 *
 * @component
 **/
const SectionPsychologistProfile = (props) => {
  const { data, id = "customer-profile", cn = "p-3 pt-80 pb-80" } = props;
  // data - содержит еще и заголовки для секции
  // console.log("111!", data);

  // const location = useLocation();
  // const { slug } = useParams();
  // const { auth, isAuthenticated } = useAuth();
  // const [profileData, setProfileData] = useState(null);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default SectionPsychologistProfile;
