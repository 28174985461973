import { SITE } from "config/config";
import { dataForm, dataMessages } from "data";
import { Fragment, useEffect, useState } from "react";
import { Button as ButtonBS, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const msgTitle = "Запись в психотерапевтическую группу";
const msgClose = "Закрыть";
const msgOk = "ОК";
const msgSubmit = "Отправить";
const msgSuccess =
  "Благодарим за регистрацию, в ближайшее время менеджер свяжется с вами и согласует время встречи";

const AUTO_CLOSE_TIME = SITE.FORM.AUTO_CLOSE_TIME;

const initState = {
  name: "",
  email: "",
  phone: "",
  description: "",
};

/**
 * Component for showing modal form "New Order" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const ModalFormGroupPsychotherapy = (props) => {
  const {
    onSubmit,
    onError,
    onClose,
    showErrorMessage = true,
    autoClose = true,
  } = props;
  const [initialValues, setInitialValues] = useState(initState);
  const [isAutoClose, setIsAutoClose] = useState(false);

  let autoCloseForm = isAutoClose && autoClose;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    // mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (data) => {
    // _debug(data);

    try {
      onSubmit && (await onSubmit(data));
    } catch (err) {
      onError && (await onError(err));

      if (showErrorMessage) toast.error(`${dataMessages.msgError}: ${err}`);

      console.error("Error form submit invalid", err);
    } finally {
      setIsAutoClose(true);
    }
  };

  const handleFormError = async (error) => {
    onError && (await onError(error));
    console.error("Error form submit invalid", error);
  };

  const handleFormClose = async () => {
    onClose && (await onClose());
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const timeoutId =
      autoCloseForm &&
      setTimeout(() => {
        handleFormClose();
      }, AUTO_CLOSE_TIME);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [autoCloseForm]);

  return (
    <Form
      onSubmit={handleSubmit(handleFormSubmit, handleFormError)}
      onReset={reset}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {msgTitle || dataForm.contact.title}
        </Modal.Title>
      </Modal.Header>
      {isAutoClose ? (
        <Fragment>
          <Modal.Body>
            <p className="lead my-4">{msgSuccess}</p>
          </Modal.Body>
          <Modal.Footer>
            <ButtonBS
              variant="secondary"
              onClick={handleFormClose}
              className=""
            >
              {msgOk}
            </ButtonBS>
          </Modal.Footer>
        </Fragment>
      ) : (
        <Fragment>
          <Modal.Body>
            {/* Name*/}
            <Form.Group className="mb-3" controlId="form.ControlInput1">
              <Form.Label>{dataForm.contact.name.label}</Form.Label>
              <Form.Control
                type="text"
                placeholder={dataForm.contact.name.placeholder}
                isInvalid={!!errors.name}
                {...register("name", {
                  required: dataForm.contact.name.required,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Email */}
            {/* <Form.Group className="mb-3" controlId="form.ControlInput2">
            <Form.Label>{dataForm.contact.email.label}</Form.Label>
            <Form.Control
              type="email"
              placeholder={dataForm.contact.email.placeholder}
              autoFocus
              {...register("email", { required: false ,  pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },})}
            />
          </Form.Group>*/}
            {/* Phone */}
            <Form.Group className="mb-3" controlId="form.ControlInput3">
              <Form.Label>{dataForm.contact.phone.label}</Form.Label>
              <Form.Control
                type="phone"
                placeholder={dataForm.contact.phone.placeholder}
                isInvalid={!!errors.phone}
                {...register("phone", {
                  required: dataForm.contact.phone.required,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Description */}
            <Form.Group className="mb-3" controlId="form.ControlTextarea1">
              <Form.Label>{dataForm.contact.description.label}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={dataForm.contact.description.placeholder}
                {...register("description", { required: false })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <small
              className="help-block mr-auto"
              style={{ marginRight: "auto" }}
            >
              Нажимая кнопку Вы соглашаетесь с<br />
              <Link to="/policies">политикой конфиденциальности</Link>
            </small>
            <ButtonBS
              variant="secondary"
              onClick={handleFormClose}
              className=""
            >
              {msgClose}
            </ButtonBS>
            <ButtonBS type="submit" className="">
              {msgSubmit}
            </ButtonBS>
          </Modal.Footer>
        </Fragment>
      )}
    </Form>
  );
};

ModalFormGroupPsychotherapy.propTypes = {};

export default ModalFormGroupPsychotherapy;
