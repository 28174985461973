import { PropTypes } from "prop-types";
import { Fragment } from "react";

/**
 * Component for showing section "Customer Profile (Tab) - Customer Settings" of the site.
 *
 * @component
 **/
const ProfileTabSettings = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className="vstack gap-4">
        {/*<!-- Notifications START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Notification Settings</h4>
          </div>

          {/*<!-- Form START -->*/}
          <form className="card-body">
            {/*<!-- Radio -->*/}
            <div className="mb-4">
              <label className="form-label">
                Newsletter<span className="text-danger">*</span>
              </label>
              <div className="d-flex gap-2 gap-sm-4 flex-wrap">
                <div className="form-check radio-bg-light">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked=""
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Daily
                  </label>
                </div>
                <div className="form-check radio-bg-light">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Twice a week
                  </label>
                </div>
                <div className="form-check radio-bg-light">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                  />
                  <label className="form-check-label" for="flexRadioDefault3">
                    Weekly
                  </label>
                </div>
                <div className="form-check radio-bg-light">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault4"
                  />
                  <label className="form-check-label" for="flexRadioDefault4">
                    Never
                  </label>
                </div>
              </div>
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy1">
                Notify me via email when logging in
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy1"
                checked=""
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy2">
                I would like to receive booking assist reminders
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy2"
                checked=""
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy3">
                I would like to receive emails about Booking promotions
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy3"
                checked=""
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy7">
                I would like to know about information and offers related to my
                upcoming trip
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy7"
                checked=""
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy4">
                Show your profile publicly
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy4"
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy6">
                Send SMS confirmation for all online payments
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy6"
              />
            </div>

            {/*<!-- Switch -->*/}
            <div className="form-check form-switch form-check-md d-flex justify-content-between mb-4">
              <label className="form-check-label ps-0 pe-4" for="checkPrivacy5">
                Check which device(s) access your account
              </label>
              <input
                className="form-check-input flex-shrink-0"
                type="checkbox"
                id="checkPrivacy5"
                checked=""
              />
            </div>

            {/*<!-- Button -->*/}
            <div className="d-sm-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm btn-primary me-2 mb-0"
              >
                Save changes
              </button>
              <a href="#" className="btn btn-sm btn-outline-secondary mb-0">
                Cancel
              </a>
            </div>
          </form>
          {/*<!-- Form END -->*/}
        </div>
        {/*<!-- Notifications END -->*/}

        {/*<!-- Security settings START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Security settings</h4>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            {/*<!-- Two factor -->*/}
            <form className="mb-3">
              <h6>Two-factor authentication</h6>
              <label className="form-label">
                Add a phone number to set up two-factor authentication
              </label>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="enter your mobile number"
              />
              <button className="btn btn-sm btn-primary">Send Code</button>
            </form>

            {/*<!-- Active sessions -->*/}
            <form>
              <h6>Active sessions</h6>
              <p className="mb-2">
                Selecting "Sign out" will sign you out from all devices except
                this one. This can take up to 10 minutes.
              </p>
              <button className="btn btn-sm btn-danger mb-0">Sign Out</button>
            </form>
          </div>
          {/*<!-- Card body END -->*/}
        </div>

        {/*<!-- Update email START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Update email</h4>
            <p className="mb-0">
              Your current email address is{" "}
              <span className="text-primary">example@gmail.com</span>
            </p>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            <form>
              {/*<!-- Email -->*/}
              <label className="form-label">
                Enter your new email id<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email id"
              />

              <div className="text-end mt-3">
                <a href="#" className="btn btn-primary mb-0">
                  Save Email
                </a>
              </div>
            </form>
          </div>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Update email END -->*/}

        {/*<!-- Update Password START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Update Password</h4>
            <p className="mb-0">
              Your current email address is{" "}
              <span className="text-primary">example@gmail.com</span>
            </p>
          </div>

          {/*<!-- Card body START -->*/}
          <form className="card-body">
            {/*<!-- Current password -->*/}
            <div className="mb-3">
              <label className="form-label">Current password</label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter current password"
              />
            </div>
            {/*<!-- New password -->*/}
            <div className="mb-3">
              <label className="form-label"> Enter new password</label>
              <div className="input-group">
                <input
                  className="form-control fakepassword"
                  placeholder="Enter new password"
                  type="password"
                  id="psw-input"
                />
                <span className="input-group-text p-0 bg-transparent">
                  <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                </span>
              </div>
            </div>
            {/*<!-- Confirm -->*/}
            <div className="mb-3">
              <label className="form-label">Confirm new password</label>
              <input
                className="form-control"
                type="password"
                placeholder="Confirm new password"
              />
            </div>

            <div className="text-end">
              <a href="#" className="btn btn-primary mb-0">
                Change Password
              </a>
            </div>
          </form>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Update Password END -->*/}

        {/*<!-- Security settings END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabSettings.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabSettings;
