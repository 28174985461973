import classNames from "classnames";
import { formatDataShort } from "components/utils/Utils";
import { BLOG_API_URI } from "config/config";
import { dataForm } from "data/dataSections";
import { PropTypes } from "prop-types";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const POST_URL = `${BLOG_API_URI}/posts`;

/**
 * Component for showing Component "Blog post card" of the site.
 *
 * @component
 **/
const PostListItem = (props) => {
  const { data, index, useAnimation } = props;
  // console.log(data);

  const LinkToPost = (props) => {
    const { slug, otherProps } = props;
    // console.log(otherProps);

    return (
      <Link to={`/blog/${slug}`} {...otherProps}>
        {props.children}
      </Link>
    );
  };

  return (
    <div className="col-md-6 col-lg-12 col-xl-6">
      <div
        className="single-blog mb-40 wow fadeInUp"
        {...(useAnimation && {
          "data-aos": "fade-up",
          "data-aos-delay": 200,
        })}
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInUp",
        }}
      >
        <div className="blog-img">
          <LinkToPost slug={data.slug}>
            <img itemProp="image" src={data.image} alt="" />
          </LinkToPost>
          <span className="date-meta">15 June, 2025</span>
        </div>

        {/* Content */}
        <div className="blog-content">
          <h4 itemProp="headline" className="item-title">
            <LinkToPost slug={data.slug}>{data.title}</LinkToPost>
          </h4>
          <p itemProp="description">{data.description}</p>

          {/* Control */}
          <LinkToPost itemProp="url" slug={data.slug} className="read-more-btn">
            {dataForm.msgReadMore} <FaArrowRightLong />
          </LinkToPost>
        </div>
      </div>
    </div>
  );
};

const PostListItem2 = (props) => {
  const { data, index, useAnimation } = props;
  //console.log(data);

  const LinkToPost = (props) => {
    const { slug, otherProps } = props;
    //console.log(otherProps);
    return (
      <Link to={`/blog/${slug}`} {...otherProps}>
        {props.children}
      </Link>
    );
  };

  return (
    <article
      className={classNames("single-blog blog-style-2 mb-60", {
        "aos-animate fadeUp": useAnimation,
      })}
      {...(useAnimation && {
        "data-aos": "fade-up",
        "data-aos-delay": 200,
      })}
    >
      <div className="blog-img media-container">
        <LinkToPost slug={data.slug}>
          <img
            itemProp="image"
            src={`${BLOG_API_URI}/posts/imagedb/${data.imageURL}`}
            alt=""
            className="media"
          />
        </LinkToPost>
      </div>
      <div className="blog-content">
        <div className="small text-muted mb-2">
          {formatDataShort(
            data.updatedDate ? data.updatedDate : data.createdDate
          )}
        </div>
        {/* <div className="card-footer">
          <ul className="post-meta d-flex mb-0">
            <li className="post-date">
              <i className="uil uil-calendar-alt"></i>
              <span>25 Jun 2022</span>
            </li>
            <li className="post-author">
              <a href="#">
                <i className="uil uil-user"></i>
                <span>By Sandbox</span>
              </a>
            </li>
            <li className="post-comments">
              <a href="#">
                <i className="uil uil-comment"></i>5<span> Comments</span>
              </a>
            </li>
            <li className="post-likes ms-auto">
              <a href="#">
                <i className="uil uil-heart-alt"></i>4
              </a>
            </li>
          </ul>
        
        </div>

        <BlogPostMetadata data={data} />

        <div className="tw-text-md tw-mt-5 tw-text-gray-300 tw-flex tw-items-center tw-flex-wrap">
          <div className="post-author tw-mb-[5px] tw-pr-5 md:tw-pr-8">
            <a className="tw-flex tw-items-center" href="/blogs/author/owen-christ">
              <img
                alt="Avatar"
                src="/images/author/author-01.jpg"
                className="tw-w-8 tw-h-8 tw-rounded-full tw-mr-2"
                height="96"
                width="96"
              />
              Owen Christ
            </a>
          </div>
          <div className="blog-meta-itemn tw-mb-[5px] tw-pr-5 md:tw-pr-8">
            <i className="tw-pr-1.5 far fa-calendar"></i>Nov 25, 2022
          </div>
          <div className="blog-meta-itemn tw-mb-[5px] tw-pr-5 md:tw-pr-8">
            <i className="tw-pr-1.5 far fa-eye"></i>120 views
          </div>
          <div className="blog-meta-itemn tw-mb-[5px]">
            <a rel="noopener noreferrer" href="#comments">
              <i className="tw-pr-1.5 far fa-comment-alt-lines"></i>2 commentss
            </a>
          </div>
        </div> */}

        <h4 itemProp="headline">
          <LinkToPost slug={data.slug}>{data.title}</LinkToPost>
        </h4>
        <p>{data.description}</p>
        <div className="blog-meta">
          <LinkToPost slug={data.slug} className="read-more-btn">
            {dataForm.msgReadMore} <FaArrowRightLong />
          </LinkToPost>
          {/*
          <span className="comment">
            <i className="lni lni-bubble"></i> 1K Comment
            </span>
            */}
        </div>
      </div>
    </article>
  );
};

PostListItem2.propTypes = {
  data: PropTypes.object,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  to: PropTypes.string,
  message: PropTypes.string,
  useAnimation: PropTypes.bool ?? true,
};

export { PostListItem, PostListItem2 };
