import {
  BlogLayout,
  BlogSinglePostPageLazy,
  LayoutCustomerProfileLazy,
  LayoutPoliciesPageLazy,
  LayoutPsychologistProfileLazy,
  PageContactsLazy,
  PageCountDownLazy,
  PageCoursesLazy,
  PageCustomerEntryLazy,
  PageDemoLazy,
  PageFAQLazy,
  PageFreePsychologicalHelpLazy,
  PageGroupPsychotherapyLazy,
  PageHomeLazy,
  PageNotFoundLazy,
  PageOurTeamLazy,
  PagePartnersPsychologistLazy,
  PagePaymentTermsLazy,
  PagePoliciesLazy,
  PagePrivacyTermsLazy,
  PageSearchLazy,
  PageServicesLazy,
  PageTermsAndConditionsLazy,
  PageTestsLazy,
  PageTypographyLazy,
  PostsByCategoryPageLazyV2,
  PostsByTagPageLazyV2,
  PostsListPageLazyV2,
} from "components/pages";
import {
  CustomerAuthPage,
  CustomerLogoutPage,
  PsychologistAuthPage,
} from "components/pages/Auth";
import { CoursePage, LessonPage } from "components/pages/Courses";
import {
  CustomerProfileTabAppointmentsLazy,
  CustomerProfileTabBioLazy,
  CustomerProfileTabDeleteLazy,
  CustomerProfileTabOrdersLazy,
  CustomerProfileTabPaymentsLazy,
  CustomerProfileTabProfileLazy,
  CustomerProfileTabSettingsLazy,
  CustomerProfileTabWishlistLazy,
} from "components/pages/Customer";
import { PaymentSuccessfulPage } from "components/pages/Payment";
import {
  PsychologistAvailableTimingsLazy,
  PsychologistProfileEducationEditLazy,
  PsychologistProfileTabAppointmentsLazy,
  PsychologistProfileTabBioLazy,
  PsychologistProfileTabDeleteLazy,
  PsychologistProfileTabEducationLazy,
  PsychologistProfileTabOrdersLazy,
  PsychologistProfileTabPaymentsLazy,
  PsychologistProfileTabProductLazy,
  PsychologistProfileTabSettingsLazy,
  PsychologistProfileTabWishlistLazy,
} from "components/pages/Psychologist";
import { PsychologistProfileTabProfile } from "components/pages/Psychologist/components/ProfileTabs";
import {
  CustomerCalendar,
  TherapistsListPage,
  TherapistsSinglePage,
} from "components/pages/Therapists";
import TherapistFilteredListPage from "components/pages/Therapists/TherapistFilteredList.page";
import { SendGA4, SendYA } from "components/utils/Analytics/Analytics";
import { isDevelopment } from "components/utils/Utils";
import { useAuth } from "contexts/AuthProvider";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import AppLayout from "./AppLayout";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  const location = useLocation();
  const { auth, isAuthenticated } = useAuth();

  useEffect(() => {
    // Google Analytics - Send pageview with a custom path
    //ReactGA.set({ page: location.pathname });
    //ReactGA.pageview(location.pathname + location.search);
    SendGA4(location.pathname + location.search);

    // Yandex Analytics - Send pageview with a custom path
    SendYA(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/countdown" element={<PageCountDownLazy />} />
      {/*<Route path="/login" element={<AuthPage />} />*/}
      {/*<Route path="/register" element={<RegisterPage />} />*/}
      <Route path="/lk/auth" element={<CustomerAuthPage />} />
      {/*<Route path="/psy/auth" element={<PsychologistAuthPage />} />*/}
      <Route path="/lk/logout" element={<CustomerLogoutPage />} />
      {/*<Route path="/auth/forgot-password" element={<ForgotPage />} />*/}

      <Route path="/psychologist/lk/auth" element={<PsychologistAuthPage />} />
      <Route
        path="/psychologist/lk/logout"
        element={<PsychologistAuthPage />}
      />

      <Route path="/" element={<AppLayout />}>
        {/* Home Page */}
        <Route index element={<PageHomeLazy />} />
        <Route path="/home" element={<PageHomeLazy />} />
        {/* Free Help Page */}
        <Route
          path="/free/psychological-help"
          element={<PageFreePsychologicalHelpLazy />}
        />
        {/* Partnership */}
        <Route path="/partnership">
          <Route index element={<PagePartnersPsychologistLazy />} />
          <Route
            path="psychologist"
            element={<PagePartnersPsychologistLazy />}
          />
        </Route>
        <Route path="/services" element={<PageServicesLazy />} />
        <Route path="/team" element={<PageOurTeamLazy />} />
        <Route path="/launcher" element={<PageCustomerEntryLazy />} />
        {/* <Route path="/gifts" element={<GiftPage />} />
        <Route path="/gifts/purchase" element={<GiftPurchasePage />} />
        <Route path="/gifts/payed" element={<GiftPayedPage />} />*/}
        {/* Therapists Page */}
        <Route path="/therapists">
          <Route index element={<TherapistsListPage />} />
          <Route path=":slug" element={<TherapistsSinglePage />} />
          <Route path="cal" element={<CustomerCalendar />} />
        </Route>
        <Route path="/land/therapists">
          <Route index element={<TherapistFilteredListPage />} />
        </Route>
        {/* OLD - 
        <Route path="/psy" element={<PsyProfilesPage />}>
          <Route index element={<PsyProfileDashboardPage />} />
          <Route path="dashboard" element={<PsyProfileDashboardPage />} />
          <Route path="request" element={<PsyProfileRequestPage />} />
          <Route path="appointments" element={<PsyProfileAppointmentsPage />} />
          <Route
            path="available-timings"
            element={<PsyProfileAvailableTimingsPage />}
          />
          <Route path="my-patients" element={<PsyProfileMyPatientsPage />} />
        </Route>*/}
        <Route path="/courses">
          <Route index element={<PageCoursesLazy />} />
          <Route path=":courseSlug/:lessonSlug" element={<LessonPage />} />
          <Route path=":courseSlug" element={<CoursePage />} />
        </Route>
        {/* <Route path="/top-themes">
          <Route index element={<DailyVideosPage />} />
        </Route> */}
        {/*<Route path="/me" element={<CustomerPage />} />*/}
        {/* CUSTOMER - PROFILE*/}
        <Route
          path="/customer"
          element={
            <ProtectedRoute
              redirectPath="/lk/auth"
              isAllowed={
                !!isAuthenticated &&
                !!auth &&
                auth.permissions.includes("CUSTOMER")
              }
            />
          }
        >
          <Route path="profile" element={<LayoutCustomerProfileLazy />}>
            <Route index element={<CustomerProfileTabProfileLazy />} />
            <Route path="bio" element={<CustomerProfileTabBioLazy />} />
            <Route
              path="appointments"
              element={<CustomerProfileTabAppointmentsLazy />}
            />
            <Route path="orders" element={<CustomerProfileTabOrdersLazy />} />
            <Route
              path="payments"
              element={<CustomerProfileTabPaymentsLazy />}
            />
            <Route
              path="wishlist"
              element={<CustomerProfileTabWishlistLazy />}
            />
            <Route
              path="settings"
              element={<CustomerProfileTabSettingsLazy />}
            />
            <Route
              path="account-delete"
              element={<CustomerProfileTabDeleteLazy />}
            />
          </Route>
        </Route>
        {/* PSYCHOLOGIST - PROFILE*/}
        <Route
          path="/psychologist"
          element={
            <ProtectedRoute
              redirectPath="/lk/auth"
              isAllowed={
                !!isAuthenticated &&
                !!auth &&
                auth.permissions.includes("PSYCHOLOGIST")
              }
            />
          }
        >
          <Route path="profile" element={<LayoutPsychologistProfileLazy />}>
            <Route index element={<PsychologistProfileTabProfile />} />
            <Route path="bio" element={<PsychologistProfileTabBioLazy />} />
            <Route
              path="education"
              element={<PsychologistProfileTabEducationLazy />}
            >
              <Route
                path=":id"
                element={<PsychologistProfileEducationEditLazy />}
              />
            </Route>
            <Route
              path="product"
              element={<PsychologistProfileTabProductLazy />}
            />
            <Route
              path="available-timings"
              element={<PsychologistAvailableTimingsLazy />}
            />
            <Route
              path="appointments"
              element={<PsychologistProfileTabAppointmentsLazy />}
            />
            <Route
              path="orders"
              element={<PsychologistProfileTabOrdersLazy />}
            />
            <Route
              path="payments"
              element={<PsychologistProfileTabPaymentsLazy />}
            />
            <Route
              path="wishlist"
              element={<PsychologistProfileTabWishlistLazy />}
            />
            <Route
              path="settings"
              element={<PsychologistProfileTabSettingsLazy />}
            />
            <Route
              path="account-delete"
              element={<PsychologistProfileTabDeleteLazy />}
            />
          </Route>
        </Route>
        <Route path="/tests" element={<PageTestsLazy />} />
        {/* <Route
          path="/tests/online-test-na-depressiyu-beka"
          element={<PageTestsLazy />}
        /> */}
        {/*<Route path="/test" element={<Profile />} />*/}
        {/*<Route path="/test1" element={<CalendarTestPage />} />*/}
        {/*<Route path="/test2" element={<TherapistAppointmentPage />} />*/}
        <Route path="/payment/successful" element={<PaymentSuccessfulPage />} />
        {/* CONTACTS */}
        <Route path="/contacts" element={<PageContactsLazy />} />
        {/* FAQ */}
        <Route path="/faq" element={<PageFAQLazy />} />
        {/* POLICY - TERMS */}
        <Route path="/policies" element={<LayoutPoliciesPageLazy />}>
          <Route index element={<PagePoliciesLazy />} />
          <Route path="terms" element={<PageTermsAndConditionsLazy />} />
          <Route path="privacy" element={<PagePrivacyTermsLazy />} />
          <Route path="payments" element={<PagePaymentTermsLazy />} />
        </Route>
        {/* BLOG - POSTS */}
        <Route path="/blog" element={<BlogLayout />}>
          <Route index element={<PostsListPageLazyV2 />} />
          <Route path=":slug" element={<BlogSinglePostPageLazy />} />
          <Route path="page/:pageNumber" element={<PostsListPageLazyV2 />} />
          <Route
            path="category/:slug/page/:pageNumber"
            element={<PostsByCategoryPageLazyV2 />}
          />
          <Route
            path="category/:slug"
            element={<PostsByCategoryPageLazyV2 />}
          />
          <Route path="category" exec element={<PagePrivacyTermsLazy />} />
          <Route
            path="tag/:slug/page/:pageNumber"
            element={<PostsByTagPageLazyV2 />}
          />
          <Route path="tag/:slug" element={<PostsByTagPageLazyV2 />} />
          <Route path="tag" exec element={<PagePrivacyTermsLazy />} />
        </Route>
        <Route path="/search" element={<PageSearchLazy />} />
        <Route
          path="/group-psychotherapy"
          element={<PageGroupPsychotherapyLazy />}
        />
        {/* <Route path="/glossary" element={<GlossaryPage />} />*/}
        {/*<Route path="/calls/video" element={<VideoCallsPage />} />*/}
        {/*<Route path="/calls/audio" element={<AudioRoomPage />} />*/}
        {/* DEMO PAGES */}
        <Route path="/demo">
          <Route index element={<PageDemoLazy />} />
          {isDevelopment && (
            <Route path="typography" element={<PageTypographyLazy />} />
          )}
        </Route>
        <Route path="/not-found404" element={<PageNotFoundLazy />} />
        {/* Catch all route */}
        <Route path="*" element={<PageNotFoundLazy />} status={404} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
