import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogTopPostsByAuthor = (params = {}, options = {}) => {
  // console.log("!", params);

  return useQuery({
    queryFn: () => BlogService.getBlogTopPostsByAuthor(params),
    queryKey: [
      "blog_top_posts_by_author",
      params.pageNo ? params.pageNo : params,
    ],
    enabled: typeof params !== typeof undefined,
  });
};
