import { SITE } from "config/config";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const SITE_NAME = SITE.NAME;
const SITE_LOCALE = SITE.LOCALE;

const SEO = (props) => {
  const {
    data,
    children,
    paged = false,
    pageNo = 0,
    pageUrl = "",
    pageTotal,
    pageCanonical = false,
  } = props;
  const { title, description, name, type, author, OG, TW, ExtraMetaTags } =
    data;
  const pageNumber = pageNo + 1;
  const fullURL = window.location.href;
  const location = useLocation();

  // console.log(data);
  // console.log(title);
  // console.log(description);
  // console.log(paged);
  // console.log(pageNo);

  if (!title || !description) console.error("Meta titles not defined");

  const renderMetaTags = () => {
    return (
      <Fragment>
        {paged ? (
          <title>{`${title} | Страница ${pageNumber}`}</title>
        ) : (
          <title>{title}</title>
        )}

        {paged ? (
          <meta
            name="description"
            content={
              pageCanonical
                ? `${description} | Страница ${pageNumber}`
                : `${description}`
            }
          />
        ) : (
          <meta name="description" content={description} />
        )}

        {paged && (
          <Fragment>
            <link
              rel="canonical"
              href={
                pageCanonical
                  ? pageNo === 0
                    ? `${pageUrl}`
                    : `${pageUrl}/page/${pageNo + 1}`
                  : `${pageUrl}`
              }
            />
            {pageNo >= 0 && pageNo < pageTotal && (
              <link rel="next" href={`${pageUrl}/page/${pageNo + 2}`} />
            )}
            {pageNo >= 1 && (
              <link
                rel="prev"
                href={pageNo === 1 ? `${pageUrl}` : `${pageUrl}/page/${pageNo}`}
              />
            )}
            <link
              rel="alternate"
              href={
                pageCanonical
                  ? pageNo === 0
                    ? `${pageUrl}`
                    : `${pageUrl}/page/${pageNo + 1}`
                  : `${pageUrl}`
              }
              hrefLang="ru"
            />
            <link
              rel="alternate"
              href={
                pageCanonical
                  ? pageNo === 0
                    ? `${pageUrl}`
                    : `${pageUrl}/page/${pageNo + 1}`
                  : `${pageUrl}`
              }
              hrefLang="x-default"
            />
          </Fragment>
        )}
        {author && <meta name="author" content={author} />}
        {/* End standard metadata tags */}
        {/* Extra meta tags */}
        {ExtraMetaTags &&
          ExtraMetaTags.map((item, index) => {
            if (!item.content) return null;
            return (
              <meta
                property={item.property}
                content={item.property}
                key={index}
              />
            );
          })}
        {/* End Twitter tags */}
        {/* OG, Facebook tags */}
        {OG &&
          OG.map((item, index) => {
            // console.log("---", item, index);

            if (item.property === "og:title" && !item.content) {
              return (
                <meta
                  property={item.property}
                  content={paged ? `${title} | Страница ${pageNumber}` : title}
                  key={index}
                />
              );
            } else if (item.property === "og:description" && !item.content) {
              return (
                <meta
                  property={item.property}
                  content={
                    paged
                      ? `${description} | Страница ${pageNumber}`
                      : description
                  }
                  key={index}
                />
              );
            } else if (item.property === "og:url" && !item.content) {
              return (
                <meta property={item.property} content={fullURL} key={index} />
              );
            } else if (item.property === "og:site_name" && !item.content) {
              return (
                <meta
                  property={item.property}
                  content={SITE_NAME}
                  key={index}
                />
              );
            } else if (item.property === "og:locale" && !item.content) {
              return (
                <meta
                  property={item.property}
                  content={SITE_LOCALE}
                  key={index}
                />
              );
            }

            if (!item.content) {
              console.error(
                "Error! Content not defined for property: ",
                item.property
              );
              return null;
            }
            return (
              <meta
                property={item.property}
                content={item.content}
                key={index}
              />
            );
          })}
        {/* End Facebook tags */}
        {/* Twitter tags */}
        {TW &&
          TW.map((item, index) => {
            if (item.property === "twitter:title" && !item.content) {
              return (
                <meta property={item.property} content={title} key={index} />
              );
            }
            if (item.property === "twitter:description" && !item.content) {
              return (
                <meta
                  property={item.property}
                  content={description}
                  key={index}
                />
              );
            }
            if (!item.content) return null;
            return (
              <meta
                property={item.property}
                content={item.content}
                key={index}
              />
            );
          })}
        {/* End Twitter tags */}
      </Fragment>
    );
  };

  return (
    <Helmet prioritizeSeoTags>
      {renderMetaTags()}
      {children}
    </Helmet>
  );
};

SEO.propTypes = {
  data: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default SEO;
