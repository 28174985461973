import { SITE } from "config/config";
import React from "react";
import { VKIcon, VKShareButton } from "react-share";

/**
 * Component for showing Component "Share Buttons" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const ShareButtons = (props) => {
  const { sharedURL } = props;
  const shareUrl = sharedURL || SITE.URL || "https://psywealth.com";
  const title = "Check out this awesome website!";

  // NOTE : https://www.npmjs.com/package/react-share

  return (
    <div>
      <VKShareButton url={shareUrl} title={title} networkName="">
        <VKIcon size={32} round />
      </VKShareButton>
      {/* <TelegramShareButton url={shareUrl} title={title} networkname="">
        <TelegramIcon size={32} round />
      </TelegramShareButton> */}
      {/* <FacebookShareButton url={shareUrl} title={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton> */}
      {/* <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton source={shareUrl} title={title} summary="">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton> */}
    </div>
  );
};

export default ShareButtons;
