import { ModalFormGroupPsychotherapy } from "components/forms/HeroOrder";
import {
  SectionCallToActionLazy,
  SectionFAQLazy,
  SectionHero,
  SectionSubscribeLazy,
  SectionTestimonialLazy,
  Service,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import {
  dataCTAMainPage as dataCTA1,
  dataFAQGroupTherapyPage as dataFAQ,
  dataGroupPsychotherapyPage,
  dataHeroGroupTherapyPage as dataHero,
  dataServiceGroupTherapyPage as dataService,
  dataSubscribe,
  dataTestimonialGroupTherapyPage as dataTestimonial,
} from "data";
import { useModal } from "hooks";
import React, { Fragment, useRef } from "react";
import EventService from "services/events.service";
import { _debug } from "utils";

// NOTE :

/**
 * Component for showing Page "Group Psychotherapy" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageGroupPsychotherapy = (props) => {
  const { id = "group-psychotherapy" } = props;

  const refCAT = useRef(null);

  const handlerHeroSubmit = async (data) => {
    _debug("Hero submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = {
        ...data,
        phone: data.phone.replace(numberRegex, ""),
      };
      console.log(newData);
      const result = await EventService.registerNewPartner(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    } finally {
      // closeModal1();
    }
  };

  const {
    modal: modal1,
    openModal: openModal1,
    closeModal: closeModal1,
  } = useModal({
    children: (
      <ModalFormGroupPsychotherapy
        onSubmit={(data) => handlerHeroSubmit(data)}
        onClose={() => closeModal1()}
      />
    ),
  });

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataGroupPsychotherapyPage.meta}>
        {/*Only canonicalize pages which are a duplicate or are near-identical.*/}
        <link
          rel="canonical"
          href="https://psywealth.com/group-psychotherapy"
        />
        {/* <script type="application/ld+json">{JSON.stringify(ldJson)}</script> */}
      </SEO>
      <PageMain id={id}>
        <SectionHero
          data={dataHero}
          type="style2"
          onScroll={executeScroll}
          onSubmit={openModal1}
          showHeaderSubtitle
        />
        <Service data={dataService} type="style2" />
        <SectionCallToActionLazy
          data={dataCTA1}
          type={"style2"}
          id={"call-to-action"}
          onSubmit={() => {}}
        />
        {/* <SectionAboutUsLazy
          data={dataAboutUs}
          type={"style1"}
          showHeader={false}
        /> */}
        {/* <SectionProblemsLazy data={dataUsedForMainPage} type={"style2"} /> */}
        {/* <SectionWhatWeDoLazy
          data={dataWhatWeDoMainPage}
          type={"style2"}
          id={"what-we-do"}
        /> */}
        {/* <SectionOverviewLazy data={dataOverviewMainPage} type={"style1"} /> */}
        {/* <SectionWhatWeDoLazy
          data={dataAltFeatureMainPage}
          type={"style1"}
          id={"what-we-do"}
        /> */}
        {/* <  <SectionFeatureTabsLazy
          data={dataFeatureTabsMainPage}
          type={"style1"}
        />
        <SectionCallToActionLazy
          data={dataCTAMainPageExtra}
          type={"style4"}
          id={"call-to-action"}
          onSubmit={() => {}}
        />
        <SectionTopPsychologistsLazy
          data={dataTopPsychologists}
          cn=""
          type={"style2"}
        /> */}
        <SectionTestimonialLazy data={dataTestimonial} type={"style1"} />
        <SectionFAQLazy data={dataFAQ} type={"style1"} />
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
        {modal1}
      </PageMain>
    </Fragment>
  );
};

export default PageGroupPsychotherapy;
