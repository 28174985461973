const Image = ({ image, ...otherProps }) => {
  console.log(image);

  if (!image || !image.src) return null;

  return (
    <img
      src={image.src}
      alt={image?.alt}
      title={image?.title}
      {...otherProps}
    />
  );
};

export default Image;
