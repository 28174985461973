import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ShareButtons from "./ShareButtons";

/**
 * Component for showing Component "Blog post Tags" of the site.
 *
 * @component
 * @version: 1.0.1
 **/
const BlogPostTags = (props) => {
  const {
    data,
    id = "post-tags",
    showHeader = false,
    wrapperStyle = "",
    sharedURL = "",
    ...otherProps
  } = props;
  // console.log(data);

  const renderList = () => (
    <ul>
      {data.tags.map((item, index) => (
        <li key={index}>
          <Link to={`/blog/tag/${item.slug}`}>{`#${item.name}`}</Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="tag-wrap">
      <div className="tags">
        {showHeader && <h4 className="tag">Тэги:</h4>}
        {renderList()}
      </div>
      <div className="social-list-wrap">
        <h4 className="share">Поделиться:</h4>

        {/* <ShareButton text="test" url={sharedURL} /> */}
        <ShareButtons sharedURL={sharedURL} />

        {/*  <ul className="social-list">
          <li>
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-pinterest-p"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-behance"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
        </ul>*/}
      </div>
    </div>
  );
};

BlogPostTags.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,

  showHeader: PropTypes.bool ?? false,

  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default BlogPostTags;
