import { isDevelopment } from "components/utils/Utils";

const { useEffect } = require("react");

const useCopy = (statement) => {
  useEffect(() => {
    const copyHandler = (event) => {
      // console.log("object");
      const selected = window.getSelection().toString();
      event.clipboardData.setData("text/plain", `${selected}\n\n${statement}`);
      event.preventDefault();
    };
    if (!isDevelopment) document.addEventListener("copy", copyHandler);
    return () => {
      document.removeEventListener("copy", copyHandler);
    };
  }, [statement]);
};

export default useCopy;
