import React from "react";
import LayoutCustomerProfile from "./CustomerProfile.layout";

const CustomerProfileTabAppointmentsLazy = React.lazy(() =>
  import(
    "./components/ProfileTabs/ProfileTabAppointments/ProfileTabAppointments"
  )
);
const CustomerProfileTabBioLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabBio/ProfileTabBio")
);
const CustomerProfileTabDeleteLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabDelete/ProfileTabDelete")
);
const CustomerProfileTabOrdersLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabOrders/ProfileTabOrders")
);
const CustomerProfileTabPaymentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabPayments/ProfileTabPayments")
);
const CustomerProfileTabProfileLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabProfile/ProfileTabProfile")
);
const CustomerProfileTabSettingsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabSettings/ProfileTabSettings")
);
const CustomerProfileTabWishlistLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabWishlist")
);

export {
  CustomerProfileTabAppointmentsLazy,
  CustomerProfileTabBioLazy,
  CustomerProfileTabDeleteLazy,
  CustomerProfileTabOrdersLazy,
  CustomerProfileTabPaymentsLazy,
  CustomerProfileTabProfileLazy,
  CustomerProfileTabSettingsLazy,
  CustomerProfileTabWishlistLazy,
  LayoutCustomerProfile,
};
